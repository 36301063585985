<template>
  <div class="space-y-4">
    <div class="space-y-4">
      <asom-alert v-if="error" variant="error" :error-message="error" />
      <div v-else-if="!isLoading" class="space-y-4">
        <asom-card title="Notifications">
          <ul class="divide-y divide-gray-200 -mx-6 -my-6 rounded-b-md">
            <template v-for="item in formattedList" :key="item.notificationId">
              <li :class="['p-4', item.isCancelled && 'bg-gray-50']">
                <div class="flex space-x-3">
                  <div class="flex-1 space-y-1">
                    <div class="flex items-center justify-between">
                      <h3 class="text-sm font-medium">{{item.title}}</h3>
                      <p class="text-sm text-gray-500">{{item.date}}</p>
                    </div>
                    <div class="flex justify-between">
                      <p class="text-sm text-gray-500">
                        {{item.content}}
                      </p>
                      <asom-link v-if="!item.isCancelled" @click="$router.push(item.matchedRoute)" class="text-sm">
                        View Details
                      </asom-link>
                    </div>
                  </div>
                </div>
              </li>
            </template>

            <!-- More items... -->
          </ul>
        </asom-card>
      </div>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { humanizeUtcDate } from '@/helpers/dateTimeHelpers';
import matchNotificationRoute from '@/helpers/matchNotificationRoute';

export default {
  name: "NotificationsList",
  computed: {
    ...mapGetters({
      notificationList: 'notifications/notificationList',
      isLoading: 'notifications/isLoading',
      error: 'notifications/error'
    }),
    formattedList() {
      return this.notificationList.map(n => ({
        ...n,
        date: humanizeUtcDate(n.dateCreated),
        matchedRoute: matchNotificationRoute(n)
      }))
    },
  },
  mounted() {
    this.$store.dispatch('notifications/refreshNotifications');
  }
};
</script>
